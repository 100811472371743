* {
  font-family: Nunito Sans;
}

body {
  background: #ffffff;
  margin: 0;
  padding: 0;
  font-family: 'Nunito Sans', sans-serif; /* Or any other font */
}

/* CustomScrollbar.css */
/* Track */
body::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

/* Handle */
body::-webkit-scrollbar-thumb {
  background: #808080; /* Color of the scrollbar handle */
  border-radius: 0px; /* Border radius of the scrollbar handle */
}

/* Handle on hover */
body::-webkit-scrollbar-thumb:hover {
  background: #d3d3d3; /* Color of the scrollbar handle on hover */
}

.fadeIn {
  opacity: 1;
  transition: opacity 1s ease-in-out;
}

.fadeOut {
  opacity: 0;
  transition: opacity 1s ease-in-out;
}
